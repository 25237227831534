* {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -o-user-select: none;
   user-select: none;
}

input,
textarea {
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -o-user-select: text;
   user-select: text;
}

body {
   margin: 0;
   padding: 0;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: transparent !important;
}

img {
   pointer-events: none;
}

.dropzone {
   color: #333;
}

.dropzoneText {
   font-size: 1em !important;
}

.rndComponent .handles {
   top: 0;
   left: 0;
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 1000;
   opacity: 0;
}

.rndComponent:hover .handles {
   opacity: 1;
}

.handles svg {
   width: 20px;
   height: 20px;
   position: absolute;
   transform-origin: center center;
}

.top svg {
   top: 0;
   left: 50%;
   transform: translateX(-50%) rotate(-90deg);
}

.right svg {
   top: 50%;
   right: 0;
   transform: translateY(-50%) rotate(0);
}

.bottom svg {
   bottom: 0;
   left: 50%;
   transform: translateX(-50%) rotate(90deg);
}

.left svg {
   position: relative;
   top: 50%;
   transform: translateY(-50%) rotate(-180deg);
}

.topLeft svg {
   top: 5px;
   left: 5px;
   transform: rotate(-135deg);
}

.topRight svg {
   top: 5px;
   right: 5px;
   transform: rotate(-45deg);
}

.bottomRight svg {
   top: -5px;
   right: 5px;
   transform: rotate(45deg);
}

.bottomLeft svg {
   top: -5px;
   left: 5px;
   transform: rotate(135deg);
}

table.decks > tr > td {
   vertical-align: top;
   padding: 0.5em;
}

table.decks > tr:nth-child(odd) > td {
   background-color: rgba(255, 255, 255, 0.1);
}

table.decks > tr > td > table td {
   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.transparentBG {
   background: url("./images/transparentbg.png") top left repeat;
   background-size: 200px;
}

.lowerThirdTitle {
   font-family: "GothamNarrow-Bold";
   font-size: 3em;
   line-height: 1.2em;
}

.lowerThirdDescription {
   font-family: "GothamNarrow-Book";
   font-size: 2em;
   line-height: 1.2em;
}

.active {
   opacity: 1;
   background-color: green;
}

.inactive {
   opacity: 0.3;
   background-color: transparent;
   background-color: gray;
}
